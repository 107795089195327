import {
    handleQueryResolve,
    buildMapToField
} from '../utils';

export default function (patientID, GenericName){
  if (patientID) {

        let attributes = [{
            param: 'PatientID',
            type: 'sql.Int',
            value: patientID
        }];
        let where = ['rx.PatientID = @PatientID'];

        if (GenericName) {
          attributes.push({
              param: 'GenericName',
              type: 'sql.VarChar(40)',
              value: '%' + GenericName + '%'
          });
          where.push('drug.GenericName LIKE @GenericName');
  
      }



    return this.query(`
      SELECT
        rx.RxNumber as RxNumber,
        CONCAT(
          drug.GenericName,
          '-',
          drug.Strength,
          ',',
          'EZETIMIBE-10MG'
      ) AS Medication,
        rx.RxID as RxID,
        drug.GenericName as GenericName,
        drug.Strength as RxStrength,
        rx.RxDate as RxDate,
        rx.QtyDispense as RxQtyDispense,
        rx.Days as RxDays,
        rx.Interval as RxInterval
      FROM
        Fillware.dbo.DrugRoot as drug
      INNER JOIN
        Fillware.dbo.Rx as rx ON drug.DIN = rx.DIN
      WHERE
      ${ where.join(' AND ')} AND
        rx.RxDate > DATEADD(month, -12, GetDate())                
        AND (CONCAT(drug.GenericName, '-',drug.Strength) IN (
          'ROSUVASTATIN CALCIUM-40MG',
          'ATORVASTATIN CALCIUM-80MG',
          'FLUVASTATIN-40MG',
          'LOVASTATIN-40MG',
          'PRAVASTATIN-40MG',
          'SIMVASTATIN-80MG'
      ) OR CONCAT(drug.GenericName, '-',drug.Strength) IN (
          'EZETIMIBE-10MG'
      ) )
      ORDER BY rx.RxDate DESC
    `, attributes)
    .then(handleQueryResolve)
    .then((records) => {
        return records.map(row => buildMapToField(row));
    });
}
}